// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../css/bootstrap/extends.css';

// Cookie-consent
@import '../css/cookieconsent.css';

@import '../css/landing-logo.css';
