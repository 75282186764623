.cc-window.cc-banner.cc-theme-block {
    backdrop-filter: blur(5px);
    background: rgba(239, 239, 239, 0.4);
    border-top: rgb(239, 239, 239) 1px solid;
}
.cc-window.cc-banner .cc-message {
    text-shadow:-1px 0px 2px #eee,0px 1px 2px #eee,-1px 1px 2px #eee,1px -1px 2px #eee;
}
.cc-window.cc-banner .cc-compliance .cc-btn {
    border-radius: 4px;
}
.cc-footer {
    padding: 20px 0 20px 0;
}
.cc-footer:after {
    content: ".";
    color: transparent;
}
