#landing-logo {
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 200px;
    border-width: 0;
    position: relative;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
